<template>
  <div class="ghost-posts">
    <div v-for="post in filteredPosts" :key="post.slug" class="post">
      <v-card-title :class="{ 'text-subtitle-1': compact, 'pb-0': compact }"><NuxtLink :to="'/articles/' + post.slug">{{ post.title }}</NuxtLink></v-card-title>
      <v-card-text :class="{ 'ml-3': compact }">
        {{ truncatedExcerpt(post.excerpt) }}
        <NuxtLink :to="'/articles/' + post.slug">...more</NuxtLink>
      </v-card-text>
    </div>
  </div>
</template>

<script setup>
import {useGhostApi} from "~/composables/useGhostApi.js";

const props = defineProps({
  limit: {
    type: String,
    default: 'all'
  },
  excerptLimit: {
    type: Number,
    default: 384
  },
  excludeSlugs: {
    type: Array,
    default: () => ['gdpr-policy', 'terms-of-service', 'privacy-policy', 'simmer-branding']
  },
  compact:{
    type: Boolean,
    default: false
  }
})

const api = useGhostApi()
const posts = await useAsyncData(`ghost-posts-${props.limit}`, () =>
    api.posts.browse({limit: props.limit, fields: ['title', 'slug', 'excerpt'], formats: 'plaintext'})
)

const filteredPosts = computed(() => {
  const postsData = posts.data.value || [];
  return postsData.filter(post => !props.excludeSlugs.includes(post.slug));
});

const truncatedExcerpt = (excerpt) => {
  return excerpt?.length > props.excerptLimit
      ? excerpt.substring(0, props.excerptLimit)
      : excerpt;
};
</script>
